<template>
  <div class="g_00-index">
    <my-search-bar>
      <el-form :inline="true">
        <!-- <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.meterageDisplayCode"
            placeholder="子目号"
            clearable>
          </el-input>
        </el-form-item>
        -->
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">刷新</el-button>
          <el-button type="primary" @click="handleCopyClick">将合同数量复制为年计划数量</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <el-table
      class="mt-3"
      :data="g_00List"
      border
      header-cell-class-name="bg-info text-light"
      :height="height"
      >
      <el-table-column fixed="left" type="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column fixed="left" prop="meterageCode" width="200" header-align="left" align="left" label="项目编码"></el-table-column>
      <el-table-column fixed="left" prop="meterageName" header-align="left" align="left" label="项目名称"></el-table-column>
      <el-table-column fixed="left" prop="unit" width="100" header-align="center" align="center" label="计量单位"></el-table-column>
      <el-table-column fixed="left" prop="price" width="100" header-align="center" align="center" label="单价(元)"></el-table-column>
      <el-table-column label="合同数量" header-align="center">
        <el-table-column prop="num" :formatter="numberFormatter" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="amount" :formatter="numberFormatter" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
      </el-table-column>
      <el-table-column label="年度计划" header-align="center">
        <el-table-column prop="currentYearPlanNum" :formatter="numberFormatter" width="120" header-align="center" align="center" label="工程量"></el-table-column>
        <el-table-column prop="currentYearPlanAmount" :formatter="numberFormatter" width="120" header-align="center" align="center" label="工作量（元）"></el-table-column>
        <el-table-column prop="currentYearPlanPercent" :formatter="numberFormatter" width="120" header-align="center" align="center" label="占合同量%"></el-table-column>
      </el-table-column>
      <el-table-column fixed="right" width="50" label="操作" header-align="center" align="center">
        <template v-if="scope.row.isLeaf" slot-scope="scope">
          <i @click="handleEditClick(scope.row)" class="text-info far fa-edit operation-button"></i>
        </template>
      </el-table-column>
    </el-table>

    <my-dialog
      ref="myDialog"
      title="编辑数量"
      size="md"
      cancel-text="取消"
      ok-text="确定"
      @ok-click="handleOk"
      >
      <el-form :model="g_00" label-width="120px">
        <el-form-item label="项目编码">
          <el-input disabled v-model="g_00.meterageCode"></el-input>
        </el-form-item>
        <el-form-item label="项目名称">
          <el-input disabled v-model="g_00.meterageName"></el-input>
        </el-form-item>
        <el-form-item label="本年计划数量">
          <el-input v-model="g_00.currentYearPlanNum"></el-input>
        </el-form-item>
      </el-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import G11Model from '@/model/G11Model'
import PageOperation from '@/mixins/PageOperation'
import { mapGetters } from 'vuex'
import auth from '@/common/auth'
import axios from 'axios'
import config from '@/config'
import utility from '@/common/utility'

export default {
  name: 'G11Index',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar
  },
  filters: {
  },
  computed: {
    height () {
      return document.body.clientHeight - 170
    },
    userGuid () {
      return auth.getUserInfo().userGuid
    },
    userIdentityGuid () {
      return auth.getUserInfo().identityGuid
    },
    couldLock () {
      return config.applyIdentities.indexOf(auth.getUserInfo().identityGuid) === -1
    },
    firstYear () {
      // TODO
      return this.year === 2020
    },
    showAddBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid && config.applyIdentities.indexOf(this.userIdentityGuid) > -1
    },
    showEditBtn () {
      return this.approvalUserGuid === auth.getUserInfo().userGuid
    },
    dataColumns () {
      if (this.g_00List.length) {
        return this.g_00List[0].children
      }
      return []
    },
    ...mapGetters('planStatExamination', {
      planStatExaminationGuid: 'getPlanStatExaminationGuid',
      tenderGuid: 'getTenderGuid',
      tenderName: 'getTenderName',
      year: 'getYear',
      approvalUserGuid: 'getApprovalUserGuid'
    })
  },
  data () {
    return {
      testValue: 1,
      total: 0,
      g_00List: [],
      g_00Model: new G11Model(),
      g_00: {
        currentYearPlanNum: 0
      },
      searchModel: {
        planStatExaminationGuid: '',
        meterageDisplayCode: ''
      },
      selectedValues: [],
      totalAmount: ''
    }
  },
  methods: {
    numberFormatter (row, column) {
      return row[column.property] === 0 ? '' : row[column.property]
    },
    cellClassName ({ row, column }) {
      if (!row.empty && column.property === 'totalMonthPercent' && parseFloat(row[column.property]) > 100) {
        return 'warning'
      }
      return ''
    },
    handleBatchDeleteClick () {
    },
    classFilter (column) {
      if (column.identityGuid === this.userIdentityGuid && this.userGuid === this.approvalUserGuid) {
        return 'editable'
      }
      return ''
    },
    handleBatchPicture (flag) {
      if (!this.selectedValues.length) {
        this.$message({
          type: 'warning',
          message: '请先选择要操作的数据！'
        })
        return
      }

      if (flag) {
        // open image selection ui
        this.$refs.myDialog.open()
      } else {
        this.doBatchPicture('')
      }
    },
    handleOk () {
      this.g_00.currentYearPlanNum = parseFloat(this.g_00.currentYearPlanNum)
      axios.post(`${config.restHost}/g_00`, this.g_00, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: 2020
        }
      })
        .then(res => {
          this.$refs.myDialog.close()
          if (res.data.code === 1) {
            this.$message({
              type: 'success',
              message: '操作成功！'
            })
            this.g_00List.find(item => item.meterageListGuid === this.g_00.meterageListGuid).currentYearPlanNum = this.g_00.currentYearPlanNum
          } else {
            this.$message({
              type: 'error',
              message: '操作失败！'
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '操作失败！'
          })
        })
    },
    handleBlur (row, type = 0) {
      if (type) {
        console.log('g_00Identity', row)
        this._approval(row)
      } else {
        this.g_00Model = new G11Model(row)
        this.g_00Model.currentMonthNum = parseFloat(row.currentMonthNum)
        this._edit()
      }
    },
    handleSearchClick () {
      this._getG_00List()
    },
    handleAddClick () {
      this.$router.push('/g_00')
    },
    handleEditClick (row) {
      this.g_00 = {
        guid: utility.getUuid(),
        meterageCode: row.meterageCode,
        meterageName: row.meterageName,
        meterageListGuid: row.meterageListGuid,
        currentYearPlanNum: 0
      }
      this.$refs.myDialog.open()
    },
    handleCopyClick () {
      this.$confirm('复制将覆盖原有年计划数据，您确定要进行复制吗？')
        .then(() => {
          axios.post(`${config.restHost}/g_00/copy`, null, {
            params: {
              tenderGuid: auth.getUserInfo().tenderGuid,
              year: 2020
            }
          })
            .then(res => {
              this.$loading().close()
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '操作成功！'
                })
                this._getG_00List()
              } else {
                this.$message({
                  type: 'error',
                  message: '操作失败！'
                })
              }
            })
            .catch(err => {
              this.$loading().close()
              console.log(err)
              this.$message({
                type: 'error',
                message: '操作失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消操作'
          })
        })
    },
    _getG_00List () {
      this.$myLoading()
      axios.get(`${config.restHost}/g_00`, {
        params: {
          tenderGuid: auth.getUserInfo().tenderGuid,
          year: 2020
        }
      })
        .then(res => {
          this.$loading().close()
          if (res.data.code === 1) {
            this.g_00List = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: '列表加载失败！'
            })
          }
        })
        .catch(err => {
          this.$loading().close()
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    }
  },
  created () {
    this._getG_00List()
  }
}
</script>

<style scoped lang="scss">
::v-deep ::-webkit-scrollbar {
  display: block;
}
::v-deep ::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
::v-deep ::-webkit-scrollbar-track {
  border-radius: 15px;
  width: 10px;
}
::v-deep ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #bbb;
  box-shadow: inset 0 0 6px rgba(200, 200, 200, 0.3);
}
::v-deep ::-webkit-scrollbar-thumb:window-inactive {
  background: #eee;
}
::v-deep .editable {
  color: red;
}
::v-deep .warning {
  background-color: red;
}
</style>
